import { useNewSettingsStore } from '~/store/newSettingsStore'

// Initializes the settings store within the function to ensure it's called only after Pinia has been attached to Nuxt.
// This avoids errors related to accessing the store too early.
const getCurrentCountryId = () => {
  const { currentCountry, userPreferences } = storeToRefs(useNewSettingsStore())
  return currentCountry.value?.Id || userPreferences.value?.CountryId
}

const nonEuCountries = {
  'United Arab Emirates': 784,
}

const nonEuropeanCountries = {
  Afghanistan: 4,
  Algeria: 999,
  'American Samoa': 16,
  Angola: 1818,
  Anguilla: 660,
  'Antigua and Barbuda': 28,
  Argentina: 32,
  Armenia: 51,
  Aruba: 533,
  Australia: 36,
  Azerbaijan: 31,
  Bahamas: 44,
  Bahrain: 48,
  Bangladesh: 880,
  Barbados: 52,
  Belize: 501,
  Benin: 204,
  Bermuda: 60,
  Bhutan: 64,
  Bolivia: 68,
  Bonaire: 535,
  Brazil: 76,
  'British Indian Ocean Territory': 86,
  'British Virgin Islands': 92,
  'Brunei Darussalam': 96,
  Cambodia: 116,
  Cameroon: 120,
  Canada: 124,
  'Canary Islands': 1811,
  'Cape Verde': 132,
  'Cayman Islands': 136,
  'Channel Islands': 830,
  Chile: 152,
  China: 156,
  Colombia: 170,
  'Cook Islands': 184,
  'Costa Rica': 188,
  Cuba: 192,
  Curaçao: 531,
  Cyprus: 196,
  'Dominican Republic': 1809,
  Ecuador: 218,
  Egypt: 818,
  'El Salvador': 222,
  Eritrea: 232,
  'Falkland Islands (Islas Malvinas)': 238,
  Fiji: 242,
  'French Guiana': 254,
  'French Polynesia': 258,
  Georgia: 268,
  Greenland: 304,
  Grenada: 308,
  Guadeloupe: 312,
  Guam: 316,
  Guatemala: 320,
  Guyana: 328,
  Haiti: 332,
  Honduras: 340,
  'Hong Kong': 344,
  India: 356,
  Indonesia: 360,
  Iran: 1814,
  Israel: 376,
  Jamaica: 388,
  Japan: 392,
  Jordan: 400,
  Kazakhstan: 7,
  Kenya: 404,
  Kuwait: 414,
  Kyrgyzstan: 417,
  Lebanon: 422,
  Lesotho: 426,
  'Libyan Arab Jamahiriya': 434,
  Macao: 446,
  Madagascar: 450,
  Malaysia: 458,
  Maldives: 462,
  Mali: 466,
  'Marshall Islands': 584,
  Martinique: 474,
  Mauritius: 480,
  Mexico: 484,
  Micronesia: 583,
  Mongolia: 496,
  Montserrat: 500,
  Morocco: 504,
  'Mount Athos (Greece)': 895,
  Namibia: 516,
  'Netherlands Antilles': 530,
  'New Caledonia': 1000,
  'New Zealand': 554,
  Nicaragua: 558,
  Nigeria: 897,
  'Norfolk Island': 574,
  'Northern Mariana Islands': 580,
  Oman: 512,
  Pakistan: 586,
  Panama: 507,
  'Papua New Guinea': 598,
  Peru: 604,
  Philippines: 608,
  'Puerto Rico': 630,
  Qatar: 634,
  Reunion: 638,
  'Saint Kitts And Nevis': 659,
  'Saint Lucia': 662,
  'Saint Martin': 1810,
  'Saint Vincent and the Grenadines': 670,
  Samoa: 882,
  'Saudi Arabia': 682,
  Senegal: 686,
  Seychelles: 690,
  Singapore: 702,
  'Sint Maarten': 534,
  'Solomon Islands': 90,
  'South Africa': 710,
  'South Korea': 410,
  'Sri Lanka': 144,
  Suriname: 740,
  Swaziland: 748,
  Taiwan: 158,
  Tanzania: 834,
  Thailand: 764,
  'Timor-Leste': 626,
  Togo: 768,
  Tonga: 776,
  'Trinidad And Tobago': 780,
  Tunisia: 216,
  Turkey: 792,
  'Turks and Caicos Islands': 1813,
  Uganda: 1817,
  'United Arab Emirates': 784,
  'United States': 840,
  Uruguay: 858,
  'US Virgin Islands': 850,
  Uzbekistan: 1815,
  Vanuatu: 548,
  Venezuela: 862,
  Vietnam: 84,
  Zimbabwe: 716,
}
const europeanCountries = {
  Albania: 1816,
  Andorra: 20,
  Austria: 40,
  Belgium: 56,
  'Bosnia and Herzegovina': 70,
  Bulgaria: 100,
  'Croatia (Hrvatska)': 191,
  'Czech Republic': 203,
  Denmark: 208,
  Estonia: 233,
  'Faroe Islands': 234,
  Finland: 246,
  France: 250,
  Germany: 276,
  Gibraltar: 292,
  Greece: 300,
  Hungary: 348,
  Iceland: 352,
  Ireland: 372,
  Italy: 380,
  Kosovo: 926,
  Latvia: 428,
  Liechtenstein: 438,
  Lithuania: 440,
  Luxembourg: 442,
  Malta: 470,
  Moldova: 498,
  Monaco: 492,
  Montenegro: 499,
  Netherlands: 528,
  Norway: 578,
  Poland: 616,
  Portugal: 620,
  'Republic of Macedonia': 807,
  Romania: 642,
  'Russian Federation': 643,
  'San Marino': 674,
  Serbia: 381,
  Slovakia: 703,
  Slovenia: 705,
  Spain: 724,
  Sweden: 752,
  Switzerland: 756,
  Ukraine: 804,
  'United Kingdom': 826,
  'Vatican City': 336,
}

const euCountries = {
  Austria: 40,
  Belgium: 56,
  Bulgaria: 100,
  Croatia: 191,
  Cyprus: 196,
  CzechRepublic: 203,
  Denmark: 208,
  Estonia: 233,
  Finland: 246,
  France: 250,
  Germany: 276,
  Greece: 300,
  Hungary: 348,
  Ireland: 372,
  Italy: 380,
  Latvia: 428,
  Lithuania: 440,
  Luxembourg: 442,
  Malta: 470,
  Netherlands: 528,
  Poland: 616,
  Portugal: 620,
  Romania: 642,
  Slovakia: 703,
  Slovenia: 705,
  Spain: 724,
  Sweden: 752,
  UnitedKingdom: 826,
  Switzerland: 756,
}

// Merge all the region-specific constants into one
const allCountries = {
  ...euCountries,
  ...nonEuCountries,
}

export const isUK = () => getCurrentCountryId() === euCountries['UnitedKingdom']
export const isGermany = () => getCurrentCountryId() === euCountries['Germany']
export const isSweden = () => getCurrentCountryId() === euCountries['Sweden']
export const isDenmark = () => getCurrentCountryId() === euCountries['Denmark']
export const isSwitzerland = () => getCurrentCountryId() === euCountries['Switzerland']
export const isFrance = () => getCurrentCountryId() === euCountries['France']
export const isUAE = () => getCurrentCountryId() === nonEuCountries['United Arab Emirates']

export function isDeGroup() {
  return (
    getCurrentCountryId() === euCountries['Germany'] ||
    getCurrentCountryId() === euCountries['Sweden'] ||
    getCurrentCountryId() === euCountries['Denmark'] ||
    getCurrentCountryId() === euCountries['Switzerland']
  )
}

export function isGmbhGroup() {
  // const settings = useNewSettingsStore()
  // return  _77Settings.Country.CompanyId == 2 // TODO: check what is the company id
  return false
}

export const isEUCountry = () => Object.values(euCountries).includes(getCurrentCountryId())
export const isEuropeanCountry = () => Object.values(europeanCountries).includes(getCurrentCountryId())
export const getSelectedCountry = () => getCurrentCountryId()
